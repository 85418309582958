import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'

import Layout from '../components/Layout'
import EmailForm from '../components/EmailForm'
import withLocation from '../hoc/withLocation'

const EmailFormWithLocation = withLocation(EmailForm)

const EmailFormTemplate = ({ data }) => {
  const { frontmatter } = data.markdownRemark

  return (
    <Layout>
      <EmailFormWithLocation {...frontmatter} />
    </Layout>
  )
}

EmailFormTemplate.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    })
  })
}

export default EmailFormTemplate

export const pageQuery = graphql`
  query EmailForm($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      frontmatter {
        title,
        content,
        variants {
          content,
          parameter
        }
      }
    }
  }
`
