import React from 'react'
import PropTypes from 'prop-types'
import ContentWrapper from '../ContentWrapper'

import * as styles from './style.module.sass'

const EmailForm = ({ title, content, variants, search }) => {
  const { answer } = search

  const index = variants.findIndex((elem) => elem.parameter === answer)

  const text = `${content}${index !== -1 ? ` ${variants[index].content}` : ''}`

  return (
    <ContentWrapper className={styles.contentWrapper}>
      <h1 className={styles.title}>{title}</h1>
      <p className={styles.content}>{text}</p>
    </ContentWrapper>
  )
}

EmailForm.propTypes = {
  title: PropTypes.string,
  content: PropTypes.string
}

export default EmailForm